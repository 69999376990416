import React from "react"
import { navigate } from "gatsby"

import Layout from "../../components/global/layout"
import { Quiz } from "../../components/quiz/Quiz"
import Modal from "../../components/block/Modal"

import { questionData } from "../../data/love-your-vulva"
import { addQuiz2Data } from "../../state/app"
import { urls } from "../../data/urls"
import ModalCorrect from "../../components/block/ModalCorrect"

export default function QuizPage() {
  return (
    <Layout title="Love your vulva">
      <Quiz
        questionData={questionData}
        globalReducerCallback={addQuiz2Data}
        onQuizComplete={() => navigate(urls.LYV.OUTRO)}
        remoteKey="quiz2"
        renderIntro={(isIntroOpen, closeIntro, introFadeOut) => (
          <Modal
            isModalOpen={isIntroOpen}
            onCloseModal={closeIntro}
            fadeOut={introFadeOut}
            title="Love your vulva" //TODO Move to JSON
            text="Everyone’s genitalia is unique and we should be celebrating that! There’s no right or wrong, so how do you feel about yours?" //TODO MOVE TO JSON
            buttonText="Take the quiz" //TODO move to JSON
            backgroundColor={props => props.theme.apricot}
          />
        )}
        renderSuccess={(
          closeCorrectModal,
          correctModalIsOpen,
          correctFadeOut,
          tagSubText,
          currentResponse,
          currentQuestionIndex
        ) => {
          return (
            <ModalCorrect
              onClick={closeCorrectModal}
              modalIsOpen={correctModalIsOpen}
              fadeOut={correctFadeOut}
              closeModal={closeCorrectModal}
              emoji={true}
              tagSubText={tagSubText}
              text={currentResponse ? currentResponse.conclusionText : ""}
              buttonText={
                currentQuestionIndex < questionData.length - 1
                  ? "Next Question"
                  : "All Finished"
              }
            />
          )
        }}
      />
    </Layout>
  )
}
