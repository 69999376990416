export const questionData = [
  {
    question: "How often do you look at your vulva in a mirror?",
    type: "radio",
    conclusionText:
      "Your vulva is an amazing part of your body and it's good to get familiar with it. It's empowering to educate yourself about your body and understand what 'normal' looks like for you. Also, by looking at your vulva regularly you'll know if something doesn't look right.",
    answers: [
      {
        text: "NEVER",
        response: "Really? Go and get a mirror now!",
      },
      {
        text: "ONCE",
        response: "Great! Why not make it a regular thing?",
      },
      {
        text: "A FEW TIMES",
        response: "Great! Why not make it a regular thing?",
      },
      {
        text: "EVERY WEEK",
        response: "Fantastic! Keep doing what you're doing!",
      },
      {
        text: "ALL THE TIME!",
        response: "Fantastic! Keep doing what you're doing!",
      },
    ],
  },
  {
    question:
      "Vulva? Lady garden? Foof? What do you call your vulva? [tick all that apply]",
    type: "check",
    conclusionText:
      "As you can see, there are lots of different words people use when talking about their vulva and vagina. If you use different words, have you ever asked yourself why? Is it because you're embarrassed to use the words vulva or vagina? Do you feel confident that you know the difference between the vulva and the vagina? Often we use different words when speaking to different people. For example you might use one word with a partner and another with a doctor or nurse. Whichever words you use, it's important you know the correct medical names so you can talk about it if something doesn't feel right. ",
    answers: [
      {
        text: "VULVA",
        response: "",
      },
      {
        text: "LADY GARDEN",
        response: "",
      },
      {
        text: "FOOF",
        response: "",
      },
      {
        text: "NUNNY",
        response: "",
      },
      {
        text: "GROWLER",
        response: "",
      },
      {
        text: "TWAT",
        response: "",
      },
      {
        text: "MUFF",
        response: "",
      },
      {
        text: "FANNY",
        response: "",
      },
      {
        text: "VAG",
        response: "",
      },
      {
        text: "VAJAYJAY",
        response: "",
      },
      {
        text: "DOWN THERE",
        response: "",
      },
      {
        text: "PRIVATE PARTS",
        response: "",
      },
      {
        text: "FRONT BOTTOM",
        response: "",
      },
      {
        text: "PUM",
        response: "",
      },
      {
        text: "YONI",
        response: "",
      },
    ],
  },
  {
    question: "When it comes to labia, how do yours make you feel? ",
    type: "radio",
    conclusionText:
      "Every vulva is unique, and that's something to celebrate. Like other parts of the vulva, labia can come in all different shapes, sizes and colours. Some people's inner labia will hang down and be visible, and most of the time one side will be longer than the other. We don’t get to see other people’s vulvas very often and when we do, it may be in porn which doesn’t give a realistic impression. If you want to look at some real vulvas to see how diverse and wonderful they all are, you can read about Laura Dodsworth's photography project: 100 Vaginas ",
    answers: [
      {
        text: "SEXY",
        response:
          "Yes! Your vulva is unique and wonderful, and should be celebrated!",
      },
      {
        text: "CONFIDENT",
        response:
          "Yes! Your labia are unique and wonderful, and should be celebrated!",
      },
      {
        text: "INDIFFERENT",
        response:
          "Ok, we want to support you to celebrate your labia in all their diversity.",
      },
      {
        text: "EMBARASSED",
        response:
          "Keep reading! We want to help you to celebrate your labia in all their diversity...",
      },
      {
        text: "ASHAMED",
        response:
          "Keep reading! We want to help you to celebrate your labia in all their diversity...",
      },
    ],
  },
  {
    question: "Let's talk about pubes! How do you keep yours?",
    type: "radio",
    conclusionText:
      "Just like the hair on our heads, pubic hair grows differently on everyone. It can be dark, fair, course, fine, curly, straight and anything and everything in between. Lots of people choose to keep leave their hair as it is, some people prefer to trim theirs or remove it completely. If you've seen vulvas in porn, you'll notice they're often hairless. Remember, what you choose to do with your pubic hair is completely up to you, and you should only do what makes YOU feel good, not what you think other people expect. Our bodies are clever, and like everything else, pubic hair is there for a reason. As well as protecting you from infection your pubes also reduce friction during sex. If you choose to remove your hair, it's common to experience some discomfort when it grows back such as bumps, itching and ingrown hairs. ",
    answers: [
      {
        text: "FULL BUSH|I love my natural hair",
        response: "",
      },
      {
        text: "FULL BUSH|I just can't be bothered to do anything else!",
        response: "",
      },
      {
        text: "TRIMMED|Just a little tidy up suits me",
        response: "",
      },
      {
        text:
          "DECORATED|I like to get creative with my pubes. Think shapes, patterns, colours!",
        response: "",
      },
      {
        text: "FULL REMOVAL|Removing mine makes me feel empowered",
        response: "",
      },
      {
        text: "FULL REMOVAL|I think it's more hygienic to remove them",
        response: "",
      },
      {
        text: "FULL REMOVAL|Hair grosses me out and I want it gone",
        response: "",
      },
      {
        text: "I MIX IT UP|Depending on how I feel at the time",
        response: "",
      },
      {
        text:
          "I MIX IT UP|I only trim / remove it when I know someone else will see it",
        response: "",
      },
    ],
  },
  {
    question: "How would you feel about a partner seeing your vulva?",
    type: "radio",
    conclusionText:
      "When it comes to relationships there's nothing more important than the relationship you have with yourself, and that includes your relationship with your own unique body. The more you get to know your body (including your vulva) the more confident you will feel about it. When it comes to sexual pleasure, spending time with yourself and exploring your body will help you to understand what turns you on – whether alone or sharing with a partner. Remember, to have a safe, fun sexual experience, everyone involved must give consent and no one should feel pressured into doing anything they're not comfortable with. Also, not everyone experiences sexual feelings. People who are asexual (or ace) don’t feel sexually attracted to anyone and feel no desire to have sex, and that's absoutely normal too.",
    answers: [
      {
        text: "SEXY",
        response:
          "Sounds like you have a great relationship with your vulva. That's what we like to hear! ",
      },
      {
        text: "CONFIDENT",
        response:
          "Sounds like you have a great relationship with your vulva. That's what we like to hear! ",
      },
      {
        text: "CONTENT",
        response: "That's good! Your relationship with your vulva is strong!",
      },
      {
        text: "INDIFFERENT",
        response:
          "Keep reading! Let's work on your relationship with your vulva...",
      },
      {
        text: "EMBARASSED",
        response:
          "Keep reading! Let's work on your relationship with your vulva...",
      },
      {
        text: "ASHAMED",
        response:
          "Keep reading! Let's work on your relationship with your vulva...",
      },
    ],
  },
]
